/*
usage:
  const store = await objectStorage<MyObject>({store: 'my-store'});
  const value = await store.get(key);
*/
export async function objectStorage(dbInfo) {
    const db = await dbConnect(dbInfo);
    function objectStore(mode) {
        return db.transaction(dbInfo.store, mode).objectStore(dbInfo.store);
    }
    function actionPromise(f) {
        return new Promise((resolve, reject) => {
            const res = f();
            res.onsuccess = (e) => resolve(e.target.result);
            res.onerror = (e) => reject(e.target.result);
        });
    }
    return {
        list: () => actionPromise(() => objectStore('readonly').getAllKeys()),
        get: (key) => actionPromise(() => objectStore('readonly').get(key)),
        getMany: (keys) => actionPromise(() => objectStore('readonly').getAll(keys)),
        put: (key, value) => actionPromise(() => objectStore('readwrite').put(value, key)),
        count: (key) => actionPromise(() => objectStore('readonly').count(key)),
        remove: (key) => actionPromise(() => objectStore('readwrite').delete(key)),
        clear: () => actionPromise(() => objectStore('readwrite').clear()),
        cursor: (keys, dir) => actionPromise(() => objectStore('readonly').openCursor(keys, dir)).then(cursor => cursor ?? undefined),
        txn: (mode) => db.transaction(dbInfo.store, mode),
    };
}
export async function dbConnect(dbInfo) {
    const dbName = dbInfo?.db || `${dbInfo.store}--db`;
    return new Promise((resolve, reject) => {
        const result = window.indexedDB.open(dbName, dbInfo?.version ?? 1);
        result.onsuccess = (e) => resolve(e.target.result);
        result.onerror = (e) => reject(e.target.error ?? 'IndexedDB Unavailable');
        result.onupgradeneeded = (e) => {
            const db = e.target.result;
            const txn = e.target.transaction;
            const store = db.objectStoreNames.contains(dbInfo.store)
                ? txn.objectStore(dbInfo.store)
                : db.createObjectStore(dbInfo.store);
            dbInfo.upgrade?.(e, store);
        };
    });
}
export async function dbExists(dbInfo) {
    const dbName = dbInfo?.db || `${dbInfo.store}--db`;
    const found = (await window.indexedDB.databases()).some(db => db.name === dbName);
    if (!found)
        return false;
    const store = await objectStorage(dbInfo);
    return (await store.count()) > 0;
}
